/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "../Style/lsm666vip.css";
import Promotion from '../Pages/Promotion'

import icon01 from "../Images/all/m-wallet1.png";
import icon02 from "../Images/all/m-d-w1.png";
import icon03 from "../Images/all/m-play2.png";
import icon04 from "../Images/all/m-affi1.png";
import icon05 from "../Images/all/m-pro1.png";

export default function Footer() {
  const [linkLogin, setLinkLogin] = useState("https://game.lsm666vip.biz/login");

  return (
    <>
      <div>
        <div className="footer">
          <div className="MB">
            <div class="footer-section-mb">
              <a href={linkLogin} style={{ margin: "0 auto" }}>
                <img src={icon01} alt="กระเป๋า" style={{ width: "40px", marginTop: "30px" }} />

              </a>
              <a href={linkLogin} style={{ margin: "0 auto" }}>
                <img src={icon02} alt="ฝากถอน" style={{ width: "65px", marginTop: "30px" }} />

              </a>
              <a href={linkLogin} style={{ margin: "0 auto" }}>
                <img src={icon03} alt="เล่นเกมส์" style={{ width: "90px", marginBottom: "5px" }} />

              </a>
              <a href={linkLogin} style={{ margin: "0 auto" }}>
                <img src={icon04} alt="แนะนำเพื่่อน" style={{ width: "70px", marginTop: "30px" }} />

              </a>
              <a href="/Promotion" style={{ margin: "0 auto" }}>
                <img src={icon05} alt="โปรโมชั่น" style={{ width: "52px", marginTop: "30px" }} />

              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
