/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "../Style/lsm666vip.css";
import Promotion from '../Pages/Promotion'
import Modal from "react-bootstrap/Modal";


import icon01 from "../Images/all/play.png";
import icon02 from "../Images/all/pro.png";
import icon03 from "../Images/all/wallet.png";
import icon04 from "../Images/all/d-w.png";
import icon05 from "../Images/all/affi.png";
import icon06 from "../Images/all/login-pc.png";
import icon07 from "../Images/all/register-pc.png";
import icon08 from "../Images/all/logo.png";
import icon09 from "../Images/all/menu.png";
import icon10 from "../Images/all/m-logo.png";
import icon11 from "../Images/all/noti.png";
import icon12 from "../Images/all/close1.png";

import tx1 from "../Images/all/tx1.png";
import tx2 from "../Images/all/tx2.png";
import tx3 from "../Images/all/tx3.png";
import tx4 from "../Images/all/tx4.png";
import tx5 from "../Images/all/tx5.png";
import tx6 from "../Images/all/tx6.png";
import tx7 from "../Images/all/tx7.png";
import tx8 from "../Images/all/tx8.png";
import tx9 from "../Images/all/tx9.png";




export default function Header() {
  const [linkLogin, setLinkLogin] = useState("https://game.lsm666vip.biz/login");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div>
        <div className="header">
          <div className="PC">
            <div class="header-section-pc">
              <a href="/">
                <img src={icon08} alt="icon" className="logo-head" />
              </a>
              <a href={linkLogin} style={{ marginTop: "2rem" }}>
                <img src={icon01} alt="เล่นเกมส์" style={{ width: "120px" }} />
              </a>
              <a href={linkLogin} style={{ marginTop: "2rem" }}>
                <img src={icon03} alt="กระเป๋า" style={{ width: "120px" }} />
              </a>
              <a href={linkLogin} style={{ marginTop: "2.1rem" }}>
                <img src={icon04} alt="ฝาก-ถอน" style={{ width: "120px" }} />
              </a>
              <a href={linkLogin} style={{ marginTop: "2.1rem" }}>
                <img src={icon05} alt="พันธรมิตร" style={{ width: "120px" }} />
              </a>
              <a href="/Promotion" style={{ marginTop: "2.1rem" }}>
                <img src={icon02} alt="โปรโมชั่น" style={{ width: "120px" }} />
              </a>
              <a href={linkLogin} style={{ marginTop: "2.6rem" }}>
                <img src={icon06} alt="เข้าสู่ระบบ" />
              </a>
              <a href="https://game.lsm666vip.biz/register" style={{ marginTop: "2.2rem" }}>
                {/* <img src={icon07} alt="สมัครสมาชิก" /> */}
              </a>
            </div>
          </div>
          <div className="MB">
            <div class="header-section-mb">
              <img src={icon09} alt="แฮมเบอเกอร์" className="item-m" onClick={handleShow} />
              <a href="/">
                <img src={icon10} alt="logo" className="logo-item" />
              </a>
              <a href="/Notifi">
                <img src={icon11} alt="แจ้งเตือน" className="item-n" style={{ width: "35px" }} />
              </a>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} className="showmenu--modal">
          <div className="wrapper--menu">
            <div className="Close--menu--Pc">
              <img src={icon12} alt="" className="Icon--close--Menu" onClick={handleClose} />
            </div>
            <div className="wrapper--section">
              <div>
                <div className="menu">
                  <div className="MBButton--tx1">
                    <div><a href={linkLogin}><img src={tx1} alt="" className="tx--1"></img></a></div>
                    <div><a href={linkLogin}><img src={tx2} alt="" className="tx--1"></img></a></div>
                    <div><a href={linkLogin}><img src={tx3} alt="" className="tx--1"></img></a></div>
                    <div><a href={linkLogin}><img src={tx4} alt="" className="tx--1"></img></a></div>
                    <div><a href={linkLogin}><img src={tx5} alt="" className="tx--1"></img></a></div>
                    <div><a href="/Promotion"><img src={tx6} alt="" className="tx--1"></img></a></div>
                    <div><a href={linkLogin}><img src={tx7} alt="" className="tx--1"></img></a></div>
                    <div><a href={linkLogin}><img src={tx8} alt="" className="tx--1"></img></a></div>
                  </div>
                  <div className="pugin"><a href={linkLogin}><img src={tx9} alt="" className="tx--1"></img></a></div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
