import React, { useEffect, useState } from "react";
import "../Style/lsm666vip.css";
import classNames from "classnames";
import Header from "../Pages/Header";
import Footer from "../Pages/Footer";

import NewPromotion from "../Images/all/textpro1.png";
import icon08 from "../Images/all/close.png";

export default function Notifi() {
  const [proType, setProType] = useState("all");

  const [closeMarquee, setCloseMarquee] = useState(true);
  return (
    <>
      <div>
        <div className="notifi">
          <Header />
          <div className="PC-">
            <div className="MB">
              {closeMarquee === true ? (
                <>
                  <div className="text-show">
                    <th>
                      <marquee scrollamount="6">
                        เมื่ออยากหาอะไรสนุกๆเล่นแก้เบื่อ ให้นึกถึงเรา LSM666VIP
                        เว็บพนันออนไลน์ที่มีทั้งเกมส์ กีฬา คาสิโน หวย
                        พร้อมด้วยโปรโมชั่นที่รองรับการเล่นของสมาชิกทุกชนิด
                        บริการรวดเร็วทันใจด้วยระบบ Auto แอดมินบริการ 24 ชั่วโมง
                        ติดปัญหาส่วนใดเเจ้งน้องแอดมินได้ตลอดเลยนะคะ
                      </marquee>
                    </th>
                    <img
                      src={icon08}
                      className="button--Close"
                      onClick={() => setCloseMarquee(false)}></img>
                  </div>
                </>
              ) : null}
            </div>
            <div className="promotion-section-Pc">
              <div className="promotion-page" >
                <div style={{ width: "15rem", margin: "1rem auto" }}><img src={NewPromotion} alt="..." /></div>

                <div className="seesion-pro">
                  <div
                    className={classNames("promotion-item", {
                      "active-promotion-category": proType === "sport",
                    })}
                  >
                    <p>ทั้งหมด</p>
                  </div>
                  <div
                    className={classNames("promotion-item", {
                      "active-promotion-category": proType === "casino",
                    })}
                  >
                    <p>ฝากเงิน</p>
                  </div>
                  <div
                    className={classNames("promotion-item", {
                      "active-promotion-category": proType === "slot",
                    })}
                  >
                    <p>ถอยเงิน</p>
                  </div>
                  <div
                    className={classNames("promotion-item", {
                      "active-promotion-category": proType === "lotto",
                    })}
                  >
                    <p>โบนัส</p>
                  </div>
                </div>
              </div>
            </div>
            <p className="noti-text-">ไม่มีรายการ</p>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
