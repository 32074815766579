import React, { useEffect, useState } from "react";
import "../Style/lsm666vip.css";
import classNames from "classnames";
import Header from "../Pages/Header";
import Footer from "../Pages/Footer";

import NewPromotion from "../Images/all/textpro.png";

import PromotionCa1 from "../Images/promotion/casino1.png";
import PromotionLO1 from "../Images/promotion/lotto1.png";
import PromotionSL1 from "../Images/promotion/slot1.png";
import PromotionSP1 from "../Images/promotion/sport1.png";

import imgcar1 from "../Images/games/casino/ca1.png";
import imgcar2 from "../Images/games/casino/ca2.png";
import imgcar3 from "../Images/games/casino/ca3.png";
import imgcar4 from "../Images/games/casino/ca4.png";
import imgcar5 from "../Images/games/casino/ca5.png";
import imgcar6 from "../Images/games/casino/ca6.png";
import imgcar7 from "../Images/games/casino/ca7.png";
import imgcar8 from "../Images/games/casino/ca8.png";
import imgcar9 from "../Images/games/casino/ca9.png";
import imgcar10 from "../Images/games/casino/ca10.png";
import imgcar11 from "../Images/games/casino/ca11.png";
import imgcar12 from "../Images/games/casino/ca12.png";
import imgcar13 from "../Images/games/casino/ca13.png";

import imgsp1 from "../Images/games/sport/sp1.png";
import imgsp2 from "../Images/games/sport/sp2.png";
import imgsp3 from "../Images/games/sport/sp3.png";
import imgsp4 from "../Images/games/sport/sp4.png";
import imgsp5 from "../Images/games/sport/sp5.png";
import imgsp6 from "../Images/games/sport/sp6.png";

import imgsl1 from "../Images/games/slot/sl1.png";
import imgsl2 from "../Images/games/slot/sl2.png";
import imgsl3 from "../Images/games/slot/sl3.png";
import imgsl4 from "../Images/games/slot/sl4.png";
import imgsl5 from "../Images/games/slot/sl5.png";
import imgsl6 from "../Images/games/slot/sl6.png";
import imgsl7 from "../Images/games/slot/sl7.png";
import imgsl8 from "../Images/games/slot/sl8.png";
import imgsl9 from "../Images/games/slot/sl9.png";
import imgsl10 from "../Images/games/slot/sl10.png";
import imgsl11 from "../Images/games/slot/sl11.png";
import imgsl12 from "../Images/games/slot/sl12.png";
import imgsl13 from "../Images/games/slot/sl13.png";
import imgsl14 from "../Images/games/slot/sl14.png";
import imgsl15 from "../Images/games/slot/sl15.png";
import imgsl16 from "../Images/games/slot/sl16.png";
import imgsl17 from "../Images/games/slot/sl17.png";
import imgsl18 from "../Images/games/slot/sl18.png";
import imgsl19 from "../Images/games/slot/sl19.png";
import imgsl20 from "../Images/games/slot/sl20.png";
import imgsl21 from "../Images/games/slot/sl21.png";
import imgsl22 from "../Images/games/slot/sl22.png";

import imglo1 from "../Images/games/lotto/lo1.png";
import imglo2 from "../Images/games/lotto/lo2.png";
import imglo3 from "../Images/games/lotto/lo3.png";

import active1 from "../Images/all/slot-active.png";
import active2 from "../Images/all/casino-active.png";
import active3 from "../Images/all/sport-active.png";
import active4 from "../Images/all/lotto-active.png";
import icon05 from "../Images/all/register-mb.png";
import icon06 from "../Images/all/login-mb.png";
import icon07 from "../Images/all/bg-tab.png";
import icon08 from "../Images/all/close.png";

export default function Home() {
  const [bannerPromotionList, setBannerPromotionList] = useState([]);
  const [bannerPromotion, setBannerPromotion] = useState([]);
  const [proType, setProType] = useState("all");
  const [linkLogin, setLinkLogin] = useState("https://game.lsm666vip.biz/login");
  const [closeMarquee, setCloseMarquee] = useState(true);

  const [bannerPro] = useState([
    {
      imageUrl: imgcar1,
      type: "casino",
    },
    {
      imageUrl: imgcar2,
      type: "casino",
    },
    {
      imageUrl: imgcar3,
      type: "casino",
    },
    {
      imageUrl: imgcar4,
      type: "casino",
    },
    {
      imageUrl: imgcar5,
      type: "casino",
    },
    {
      imageUrl: imgcar6,
      type: "casino",
    },
    {
      imageUrl: imgcar7,
      type: "casino",
    },
    {
      imageUrl: imgcar8,
      type: "casino",
    },
    {
      imageUrl: imgcar9,
      type: "casino",
    },
    {
      imageUrl: imgcar10,
      type: "casino",
    },
    {
      imageUrl: imgcar11,
      type: "casino",
    },
    {
      imageUrl: imgcar12,
      type: "casino",
    },
    {
      imageUrl: imgcar13,
      type: "casino",
    },

    {
      imageUrl: imgsp1,
      type: "sport",
    },
    {
      imageUrl: imgsp2,
      type: "sport",
    },
    {
      imageUrl: imgsp3,
      type: "sport",
    },
    {
      imageUrl: imgsp4,
      type: "sport",
    },
    {
      imageUrl: imgsp5,
      type: "sport",
    },
    {
      imageUrl: imgsp6,
      type: "sport",
    },

    {
      imageUrl: imgsl1,
      type: "slot",
    },
    {
      imageUrl: imgsl2,
      type: "slot",
    },
    {
      imageUrl: imgsl3,
      type: "slot",
    },
    {
      imageUrl: imgsl4,
      type: "slot",
    },
    {
      imageUrl: imgsl5,
      type: "slot",
    },
    {
      imageUrl: imgsl6,
      type: "slot",
    },
    {
      imageUrl: imgsl7,
      type: "slot",
    },
    {
      imageUrl: imgsl8,
      type: "slot",
    },
    {
      imageUrl: imgsl9,
      type: "slot",
    },
    {
      imageUrl: imgsl10,
      type: "slot",
    },
    {
      imageUrl: imgsl11,
      type: "slot",
    },
    {
      imageUrl: imgsl12,
      type: "slot",
    },
    {
      imageUrl: imgsl13,
      type: "slot",
    },
    {
      imageUrl: imgsl14,
      type: "slot",
    },
    {
      imageUrl: imgsl15,
      type: "slot",
    },
    {
      imageUrl: imgsl16,
      type: "slot",
    },
    {
      imageUrl: imgsl17,
      type: "slot",
    },
    {
      imageUrl: imgsl18,
      type: "slot",
    },
    {
      imageUrl: imgsl19,
      type: "slot",
    },
    {
      imageUrl: imgsl20,
      type: "slot",
    },
    {
      imageUrl: imgsl21,
      type: "slot",
    },
    {
      imageUrl: imgsl22,
      type: "slot",
    },


    {
      imageUrl: imglo1,
      type: "lotto",
    },
    {
      imageUrl: imglo2,
      type: "lotto",
    },
    {
      imageUrl: imglo3,
      type: "lotto",
    },
  ]);
  useEffect(() => {
    setBannerPromotionList(bannerPro);
    setBannerPromotion(bannerPro);
  }, [bannerPro]);

  const updatePromotion = (proType) => {
    const promotionFilter = bannerPromotionList.filter(
      (i) => i.type === proType
    );
    setBannerPromotion(promotionFilter);
    setProType(proType);
  };

  // eslint-disable-next-line no-unused-vars
  const updatePromotionAll = (proType) => {
    setBannerPromotion(bannerPromotionList);
    setProType(proType);
  };
  return (
    <>
      <div>
        <div className="home">
          <Header />
          <div className="PC">
            <div className="home-section-Pc">
              <div className="home-left">
                <div className="div-main-promotion-page promotion-bg-image">
                  <div className="seesion-title-catgory">
                    {proType === "slot" ?
                      <>
                        <img src={active1} className="img-banner-pro" alt="..." style={{ width: "140px", height: "120px" }} />
                      </>
                      : <>
                        <div
                          className={classNames("slot", {
                            "active-promotion-category": proType === "slot",
                          })}
                          onClick={() => {
                            updatePromotion("slot");
                          }}
                        >
                        </div>
                      </>}
                    {proType === "casino" ?
                      <>
                        <img src={active2} className="img-banner-pro" alt="..." style={{ width: "140px", height: "120px" }} />
                      </>
                      : <>
                        <div
                          className={classNames("casino", {
                            "active-promotion-category": proType === "casino",
                          })}
                          onClick={() => {
                            updatePromotion("casino");
                          }}
                        >
                        </div>
                      </>}
                    {proType === "sport" ?
                      <>
                        <img src={active2} className="img-banner-pro" alt="..." style={{ width: "140px", height: "120px" }} />
                      </>
                      : <>
                        <div
                          className={classNames("sport", {
                            "active-promotion-category": proType === "sport",
                          })}
                          onClick={() => {
                            updatePromotion("sport");
                          }}
                        >
                        </div>
                      </>}
                    {proType === "lotto" ?
                      <>
                        <img src={active2} className="img-banner-pro" alt="..." style={{ width: "140px", height: "120px" }} />
                      </>
                      : <>
                        <div
                          className={classNames("lotto", {
                            "active-promotion-category": proType === "lotto",
                          })}
                          onClick={() => {
                            updatePromotion("lotto");
                          }}
                        >
                        </div>
                      </>}
                  </div>
                  <div className="session-banner-promotion-box">
                    <div className="session-banner-promotion">
                      {bannerPromotion.map((row, index) => (
                        <div className="box-banner-promotion" key={index}>
                          <a href="https://game.lsm666vip.biz/login">
                            <img
                              src={row.imageUrl}
                              draggable={false}
                              className="w-100"
                              alt={row.type}
                            />
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="home-rigth">
                <img
                  src={NewPromotion}
                  className="img-promotion-memu"
                  alt="..."
                />
                <a href="https://game.lsm666vip.biz/login">
                  <img src={PromotionCa1} className="img-banner-pro" alt="..." />
                  <img src={PromotionLO1} className="img-banner-pro" alt="..." />
                  <img src={PromotionSL1} className="img-banner-pro" alt="..." />
                  <img src={PromotionSP1} className="img-banner-pro" alt="..." />
                </a>
              </div>
            </div>
          </div>
          {closeMarquee === true ? (
            <>
              <div className="text-show">
                <th>
                  <marquee scrollamount="6">
                    เมื่ออยากหาอะไรสนุกๆเล่นแก้เบื่อ ให้นึกถึงเรา LSM666VIP
                    เว็บพนันออนไลน์ที่มีทั้งเกมส์ กีฬา คาสิโน หวย
                    พร้อมด้วยโปรโมชั่นที่รองรับการเล่นของสมาชิกทุกชนิด
                    บริการรวดเร็วทันใจด้วยระบบ Auto แอดมินบริการ 24 ชั่วโมง
                    ติดปัญหาส่วนใดเเจ้งน้องแอดมินได้ตลอดเลยนะคะ
                  </marquee>
                </th>
                <img
                  src={icon08}
                  className="button--Close"
                  onClick={() => setCloseMarquee(false)}></img>
              </div>
            </>
          ) : null}
          <div className="MB">
            <div className="home-mb">
              <div className="log-regis">
                <a href={linkLogin} style={{ marginTop: "6px" }}>
                  <img src={icon06} alt="เข้าสู่ระบบ" />
                </a>
                <a href="https://game.lsm666vip.biz/register">
                  {/* <img src={icon05} alt="สมัครสมาชิก" /> */}
                </a>
              </div>
              <div className="promotion-page-">
                <div className="seesion-title-catgory">
                  <div
                    className={classNames("slot", {
                      "active-promotion-category": proType === "slot",
                    })}
                    onClick={() => {
                      updatePromotion("slot");
                    }}
                  >
                  </div>
                  <div
                    className={classNames("casino", {
                      "active-promotion-category": proType === "casino",
                    })}
                    onClick={() => {
                      updatePromotion("casino");
                    }}
                  >

                  </div>
                  <div
                    className={classNames("sport", {
                      "active-promotion-category": proType === "sport",
                    })}
                    onClick={() => {
                      updatePromotion("sport");
                    }}
                  >

                  </div>
                  <div
                    className={classNames("lotto", {
                      "active-promotion-category": proType === "lotto",
                    })}
                    onClick={() => {
                      updatePromotion("lotto");
                    }}
                  >

                  </div>
                </div>
                <div className="session-banner-promotion-box-">
                  <div className="session-banner-promotion-">
                    {bannerPromotion.map((row, index) => (
                      <div className="box-banner-promotion" key={index}>
                        <a href="https://game.lsm666vip.biz/login">
                          <img
                            src={row.imageUrl}
                            draggable={false}
                            className="w-100"
                            alt={row.type}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
