import React, { useEffect, useState } from "react";
import "../Style/lsm666vip.css";
import classNames from "classnames";
import Header from "../Pages/Header";
import Footer from "../Pages/Footer";

import NewPromotion from "../Images/all/textpro-pc.png";

import PromotionCa1 from "../Images/promotion/casino1.png";
import PromotionCa2 from "../Images/promotion/casino2.png";
import PromotionCa3 from "../Images/promotion/casino3.png";

import PromotionLO1 from "../Images/promotion/lotto1.png";

import PromotionSL1 from "../Images/promotion/slot1.png";
import PromotionSL2 from "../Images/promotion/slot2.png";
import PromotionSL3 from "../Images/promotion/slot3.png";

import PromotionSP1 from "../Images/promotion/sport1.png";
import PromotionSP2 from "../Images/promotion/sport2.png";
import PromotionSP3 from "../Images/promotion/sport3.png";

import icon08 from "../Images/all/close.png";

export default function Promotion() {
  const [bannerPromotionList, setBannerPromotionList] = useState([]);
  const [bannerPromotion, setBannerPromotion] = useState([]);
  const [proType, setProType] = useState("all");

  const [bannerPro] = useState([
    {
      imageUrl: PromotionCa1,
      type: "casino",
    },
    {
      imageUrl: PromotionCa2,
      type: "casino",
    },
    {
      imageUrl: PromotionCa3,
      type: "casino",
    },

    {
      imageUrl: PromotionSP1,
      type: "sport",
    },
    {
      imageUrl: PromotionSP2,
      type: "sport",
    },
    {
      imageUrl: PromotionSP3,
      type: "sport",
    },

    {
      imageUrl: PromotionSL1,
      type: "slot",
    },
    {
      imageUrl: PromotionSL2,
      type: "slot",
    },
    {
      imageUrl: PromotionSL3,
      type: "slot",
    },

    {
      imageUrl: PromotionLO1,
      type: "lotto",
    },
  ]);
  useEffect(() => {
    setBannerPromotionList(bannerPro);
    setBannerPromotion(bannerPro);
  }, [bannerPro]);

  const updatePromotion = (proType) => {
    const promotionFilter = bannerPromotionList.filter(
      (i) => i.type === proType
    );
    setBannerPromotion(promotionFilter);
    setProType(proType);
  };

  const updatePromotionAll = (proType) => {
    setBannerPromotion(bannerPromotionList);
    setProType(proType);
  };
  const [closeMarquee, setCloseMarquee] = useState(true);
  return (
    <>
      <div>
        <div className="promotion">
          <Header />
          <div className="PC-">
            {closeMarquee === true ? (
              <>
                <div className="text-show">
                  <th>
                    <marquee scrollamount="6">
                      เมื่ออยากหาอะไรสนุกๆเล่นแก้เบื่อ ให้นึกถึงเรา LSM666VIP
                      เว็บพนันออนไลน์ที่มีทั้งเกมส์ กีฬา คาสิโน หวย
                      พร้อมด้วยโปรโมชั่นที่รองรับการเล่นของสมาชิกทุกชนิด
                      บริการรวดเร็วทันใจด้วยระบบ Auto แอดมินบริการ 24 ชั่วโมง
                      ติดปัญหาส่วนใดเเจ้งน้องแอดมินได้ตลอดเลยนะคะ
                    </marquee>
                  </th>
                  <img
                    src={icon08}
                    className="button--Close"
                    onClick={() => setCloseMarquee(false)}></img>
                </div>
              </>
            ) : null}
            <div className="MB">
            </div>
            <div className="promotion-section-Pc">
              <div className="promotion-page">
                <img src={NewPromotion} className="-img-promotion-memu" alt="..." />
                <div className="seesion-pro">
                  <div className={classNames("promotion-item", {
                    "active-promotion-category": proType === "all",
                  })}
                    onClick={() => {
                      updatePromotionAll("all");
                    }}
                  >
                    <p className="mb-0">ทั้งหมด</p>
                  </div>
                  <div
                    className={classNames("promotion-item", {
                      "active-promotion-category": proType === "sport",
                    })}
                    onClick={() => {
                      updatePromotion("sport");
                    }}
                  >
                    <p>กีฬา</p>
                  </div>
                  <div
                    className={classNames("promotion-item", {
                      "active-promotion-category": proType === "casino",
                    })}
                    onClick={() => {
                      updatePromotion("casino");
                    }}
                  >
                    <p>คาสิโน</p>
                  </div>
                  <div
                    className={classNames("promotion-item", {
                      "active-promotion-category": proType === "slot",
                    })}
                    onClick={() => {
                      updatePromotion("slot");
                    }}
                  >
                    <p>สล๊อต</p>
                  </div>
                  <div
                    className={classNames("promotion-item", {
                      "active-promotion-category": proType === "lotto",
                    })}
                    onClick={() => {
                      updatePromotion("lotto");
                    }}
                  >
                    <p>หวย</p>
                  </div>
                </div>
                <div className="session-promotion-box">
                  <div className="session-banner-promotion">
                    {bannerPromotion.map((row, index) => (
                      <div className="box-banner-promotion" key={index}>
                        <a href="https://game.lsm666vip.biz/login">
                          <img
                            src={row.imageUrl}
                            draggable={false}
                            className="w-100"
                            alt={row.type}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
